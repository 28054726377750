input.edit-input:hover {
  border-color: lightgray;
  border-width: 2px;
  background-color: #f2f2f2;
}

input.edit-input {
  border-color: white;
  position: relative;
  border-width: 0px;
}

input.edit-input-button {
  position: absolute;
  z-index: 10px;
  background-color: lightgrey;
}

.element-visible {
  display: block;
}
.element-hidden {
  display: none;
}

.formula {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  table-layout: auto;
}

.formula td {
  border: 1px solid #ddd;
  padding: 2px;
  color: dimgray;
}

.formula tr:nth-child(even) {
  background-color: #f2f2f2;
}

.formula tr:hover {
  background-color: #ddd;
}

.formula th {
  border: 1px solid #ddd;
  padding: 2px;
  text-align: center;
  background-color: lightgrey;
  color: black;
}

.formula > span:hover {
  background-color: lightgrey;
  color: black;
}
.formula-popper {
  z-index: 99999999 !important;
  position: absolute;
  padding: 2px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.pop-top {
  z-index: 900px !important;
  opacity: 0.8;
}

.pop-bottom {
  bottom: 12px;
}
